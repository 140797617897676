import React, { useEffect, useState } from "react"
import browserLang from "browser-lang"
import { navigate } from "gatsby"

import styled from "styled-components"
import { ThemeProvider } from "styled-components"

import { IntlContextProvider } from "gatsby-plugin-intl"
import "../../static/layout.css"

import AcceptCookieForm from "../components/AcceptCookieForm"
import ModalsContainer from "../components/ModalsContainer"
import TopBanner from "../common/TopBanner"
import SEO from "../seo"

import DevelopersPage from "../developers"
import themes from "../developers/theme.json"
import useContent from "../developers/useContent"

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = ({ location, keywords }) => {
  const [theme, setTheme] = useState("dark")
  const content = useContent()

  useEffect(() => {
    if (typeof window == "undefined") return
    if (window.location.pathname === "/" || window.location.pathname == "") {
      try {
        // Get browser language with fallback
        const detectedLang = browserLang({
          languages: ["en-us", "ru-ru", "ru", "en"],
          fallback: "en-us",
        }) || "en-us"; // Ensure we always have a value even if browserLang returns null/undefined
        
        // Only redirect for Russian language
        if (detectedLang === "ru-ru" || detectedLang === "ru") {
          navigate(`/ru-ru`, { replace: true })
        }
        // For English, stay at root path (no redirect needed)
      } catch (error) {
        // Log error but don't break user experience
        console.error("Language detection error:", error);
        // No redirect needed on error - stay at root path which is our English default
      }
    }
  }, [])

  const handleChangeTheme = () =>
    setTheme(prevValue => (prevValue === "light" ? "dark" : "light"))

  return (
    <ThemeProvider theme={themes[theme]}>
      <IntlContextProvider>
        <Wrap id="layout">
          <AcceptCookieForm />
          <SEO
            title={content.metaTitle}
            description={content.metaDescription}
            keywords={keywords}
            location={location}
          />
          <TopBanner />
          <DevelopersPage
            location={location}
            theme={theme}
            changeTheme={handleChangeTheme}
          />
          <ModalsContainer location={location} />
        </Wrap>
      </IntlContextProvider>
    </ThemeProvider>
  )
}

export default Layout
